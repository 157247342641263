<template>
  <v-row class="mt-4">
    <v-col :cols="12" md="8" offset-md="2" lg="6" offset-lg="3">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>Covenant Printer Drivers</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p style="margin-bottom:0">Please download the appropriate driver for your device to install the PaperCut Print Client in order to print to the Covenant printers.</p>
        </v-card-text>
        <v-list class="pa-0">
          <v-list-item v-for="{ os, link, icon } in drivers" :key="os" :href="linkBase + link" style="border-top: 1px solid #eee" download>
            <v-list-item-avatar v-if="icon">
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ os }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
      <v-card class="mt-3">
        <v-list>
          <v-list-item :href="linkBase + 'installation-instructions.pdf'" target="_blank">
            <v-list-item-avatar>
              <v-icon>fal fa-file-pdf</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Installation Instructions</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item :href="linkBase + 'mac-student-printing-instructions.pdf'" target="_blank">
            <v-list-item-avatar>
              <v-icon>fal fa-file-pdf</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Mac Student Instructions</v-list-item-title>
          </v-list-item> -->
          <v-list-item :href="linkBase + 'print-anywhere-user-guide.pdf.pdf'" target="_blank">
            <v-list-item-avatar>
              <v-icon>fal fa-file-pdf</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Print Anywhere User Guide</v-list-item-title>
          </v-list-item>
          <v-list-item href="https://scotsprint.covenant.edu:9192/app?service=page/UserWebPrint" target="_blank">
            <v-list-item-avatar>
              <v-icon>fal fa-print</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Web Print</v-list-item-title>
              <v-list-item-subtitle>Upload your document as a PDF without needing to install the printers</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item href="https://scotsprint.covenant.edu/app?service=page/UserSummary" target="_blank">
            <v-list-item-avatar>
              <v-icon>fal fa-check-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Check Wireless Printing Balance</v-list-item-title>
              <v-list-item-subtitle style="white-space:normal">At your request we can add paper to your account in $5.00 increments. The amount is then billed to your student account as a print charge. The funds in your Papercut account will roll from Fall to Spring semester, but not from year to year. If you would like to purchase more paper for your account please email <a href="mailro:techsupport@covenant.edu" target="_blank">techsupport@covenant.edu</a> the dollar value that you would like to add.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { ref } from '@vue/composition-api'

export default {
  setup (props) {
    const linkBase = ref('https://portal.covenant.edu/media/print-drivers/')
    const drivers = ref([
      { os: 'Chrome', link: 'chrome-policy[printsvr22.covenant.edu].txt', icon: 'fab fa-chrome' },
      { os: 'Windows', link: 'pc-print-deploy-client[printsvr22.covenant.edu].msi', icon: 'fab fa-windows' },
      { os: 'Mac OSX', link: 'pc-print-deploy-client[printsvr22.covenant.edu].dmg', icon: 'fab fa-apple' },
      { os: 'Debian Linux', link: 'pc-print-deploy-client[printsvr22.covenant.edu].deb', icon: 'fab fa-linux' },
      { os: 'Red Hat Linux', link: 'pc-print-deploy-client[printsvr22.covenant.edu].rpm', icon: 'fab fa-redhat' }
    ])
    return {
      linkBase,
      drivers
    }
  }
}
</script>
